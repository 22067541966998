import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';

import FourthDay from '../../../assets/images/ru/days/fourth/fourth.png';
import ENFourthDay from '../../../assets/images/en/days/fourth/fourth.png';
import FirstItem from '../../../assets/images/ru/days/fourth/first-item.png';
import SecondItem from '../../../assets/images/ru/days/fourth/second-item.png';
import ThirdItem from '../../../assets/images/ru/days/fourth/third-item.png';

const FourthDayPage = () => {
	const intl = useIntl();

	return (
		<div className="fourth-day ">
			<div className="fourth-day__image">
				{intl.locale === 'ru' ? <img src={FourthDay} alt="" /> : <img src={ENFourthDay} alt="" />}
			</div>
			<div className="fourth-day__content">
				<h3 className="fourth-day__title days-title">{intl.formatMessage({ id: 'days.start' })}</h3>
				<span className="fourth-day__untitle days-untitle">
					{intl.formatMessage({ id: 'day4.firstTitle' })}
					<span className="number">1</span>
				</span>
				<div className="fourth-day__quests day-quests">
					<div className="fourth-day__quest day-quest">
						<div className="fourth-day__quest__item day-quest__item">
							<img
								className="fourth-day__quest__item-image day-quest__item-image"
								src={FirstItem}
								alt="Scope.gg Skin"
							/>
							<span className="fourth-day__quest__item-title day-quest__item-title">
								AWP <br /> MW Hyper Beast
							</span>
							<span className="fourth-day__quest-item-prize day-quest__item-prize">
								{intl.formatMessage({ id: 'days.award' })}
							</span>
						</div>
						<div className="fourth-day__quest-content day-quest__content">
							<span className="fourth-day__quest-content-title day-quest__content-title">
								{intl.formatMessage({ id: 'days.task' })} #1:
							</span>
							<span className="fourth-day__quest-content-untitle day-quest__content-untitle">
								{`${intl.formatMessage({ id: 'day4.task1' })}`}
							</span>
						</div>
					</div>
					<div className="fourth-day__quest day-quest">
						<div className="fourth-day__quest__item day-quest__item">
							<img
								className="fourth-day__quest__item-image day-quest__item-image"
								src={SecondItem}
								alt="Scope.gg Skin"
							/>
							<span className="fourth-day__quest__item-title day-quest__item-title">
								AK-47 <br /> MW Frontside Misty
							</span>
							<span className="fourth-day__quest-item-prize day-quest__item-prize">
								{intl.formatMessage({ id: 'days.award' })}
							</span>
						</div>
						<div className="fourth-day__quest-content day-quest__content">
							<span className="fourth-day__quest-content-title day-quest__content-title">
								{intl.formatMessage({ id: 'days.task' })} #2:
							</span>
							<span className="fourth-day__quest-content-untitle day-quest__content-untitle">
								{`${intl.formatMessage({ id: 'day4.task2' })}`}
							</span>
						</div>
					</div>
					<div className="fourth-day__quest day-quest">
						<div className="fourth-day__quest__item day-quest__item">
							<img
								className="fourth-day__quest__item-image day-quest__item-image"
								src={ThirdItem}
								alt="Scope.gg Skin"
							/>
							<span className="fourth-day__quest__item-title day-quest__item-title">
								FAMAS <br /> FN Commemoration
							</span>
							<span className="fourth-day__quest-item-prize day-quest__item-prize">
								{intl.formatMessage({ id: 'days.award' })}
							</span>
						</div>
						<div className="fourth-day__quest-content day-quest__content">
							<span className="fourth-day__quest-content-title day-quest__content-title">
								{intl.formatMessage({ id: 'days.task' })} #3:
							</span>
							<span className="fourth-day__quest-content-untitle day-quest__content-untitle">
								{`${intl.formatMessage({ id: 'day4.task3' })}`}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FourthDayPage;
