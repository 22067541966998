import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';

import ThirdDay from '../../../assets/images/ru/days/third/third.png';
import ENThirdDay from '../../../assets/images/en/days/third/third.png';
import FirstItem from '../../../assets/images/ru/days/third/first-item.png';
import SecondItem from '../../../assets/images/ru/days/third/second-item.png';
import ThirdItem from '../../../assets/images/ru/days/third/third-item.png';

const ThirdDayPage = () => {
	const intl = useIntl();

	return (
		<div className="third-day ">
			<div className="third-day__image">
				{intl.locale === 'ru' ? <img src={ThirdDay} alt="" /> : <img src={ENThirdDay} alt="" />}
			</div>
			<div className="third-day__content">
				<h3 className="third-day__title days-title">{intl.formatMessage({ id: 'days.start' })}</h3>
				<span className="third-day__untitle days-untitle">
					{intl.formatMessage({ id: 'day3.firstTitle' })}
					<span className="number">1</span>
				</span>
				<div className="third-day__quests day-quests">
					<div className="third-day__quest day-quest">
						<div className="third-day__quest__item day-quest__item">
							<img className="third-day__quest__item-image day-quest__item-image" src={FirstItem} alt="Scope.gg Skin" />
							<span className="third-day__quest__item-title day-quest__item-title">
								M4A4 <br /> FN Hellfire
							</span>
							<span className="third-day__quest-item-prize day-quest__item-prize">
								{intl.formatMessage({ id: 'days.award' })}
							</span>
						</div>
						<div className="third-day__quest-content day-quest__content">
							<span className="third-day__quest-content-title day-quest__content-title">
								{intl.formatMessage({ id: 'days.task' })} #1:
							</span>
							<span className="third-day__quest-content-untitle day-quest__content-untitle">
								{`${intl.formatMessage({ id: 'day3.task1' })}`}
							</span>
						</div>
					</div>
					<div className="third-day__quest day-quest">
						<div className="third-day__quest__item day-quest__item">
							<img
								className="third-day__quest__item-image day-quest__item-image"
								src={SecondItem}
								alt="Scope.gg Skin"
							/>
							<span className="third-day__quest__item-title day-quest__item-title">
								M4A1-S <br /> MW Hyper Beast
							</span>
							<span className="third-day__quest-item-prize day-quest__item-prize">
								{intl.formatMessage({ id: 'days.award' })}
							</span>
						</div>
						<div className="third-day__quest-content day-quest__content">
							<span className="third-day__quest-content-title day-quest__content-title">
								{intl.formatMessage({ id: 'days.task' })} #2:
							</span>
							<span className="third-day__quest-content-untitle day-quest__content-untitle">
								{`${intl.formatMessage({ id: 'day3.task2' })}`}
							</span>
						</div>
					</div>
					<div className="third-day__quest day-quest">
						<div className="third-day__quest__item day-quest__item">
							<img className="third-day__quest__item-image day-quest__item-image" src={ThirdItem} alt="Scope.gg Skin" />
							<span className="third-day__quest__item-title day-quest__item-title">
								M4A4 MW Desolate <br /> Space
							</span>
							<span className="third-day__quest-item-prize day-quest__item-prize">
								{intl.formatMessage({ id: 'days.award' })}
							</span>
						</div>
						<div className="third-day__quest-content day-quest__content">
							<span className="third-day__quest-content-title day-quest__content-title">
								{intl.formatMessage({ id: 'days.task' })} #3:
							</span>
							<span className="third-day__quest-content-untitle day-quest__content-untitle">
								{`${intl.formatMessage({ id: 'day3.task3' })}`}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ThirdDayPage;
