import React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'gatsby-plugin-react-intl';

//firstday
import FDF from '../../../assets/images/ru/days/first/first-item.png';
import FDS from '../../../assets/images/ru/days/first/second-item.png';
import FDT from '../../../assets/images/ru/days/first/third-item.png';

//secondday
import SDF from '../../../assets/images/ru/days/second/first-item.png';
import SDS from '../../../assets/images/ru/days/second/second-item.png';
import SDT from '../../../assets/images/ru/days/second/third-item.png';

//thirday
import TDF from '../../../assets/images/ru/days/third/first-item.png';
import TDS from '../../../assets/images/ru/days/third/second-item.png';
import TDT from '../../../assets/images/ru/days/third/third-item.png';

//fourthday
import FourthDF from '../../../assets/images/ru/days/fourth/first-item.png';
import FourthDS from '../../../assets/images/ru/days/fourth/second-item.png';
import FourthDT from '../../../assets/images/ru/days/fourth/third-item.png';

//fifthday
import FifthDF from '../../../assets/images/ru/days/fifth/first-item.png';
import FifthDS from '../../../assets/images/ru/days/fifth/second-item.png';
import FifthDT from '../../../assets/images/ru/days/fifth/third-item.png';

import KnifeImage from '../../../assets/images/knife-small.png';
import Steam from '../../../assets/images/steam.svg';
import Faceit from '../../../assets/images/faceit.svg';
import fb from '../../../assets/images/fb.svg';
import twitter from '../../../assets/images/twitter.svg';
import vk from '../../../assets/images/vk.svg';
import instagram from '../../../assets/images/instagram.svg';
import { authService } from '../../../services/authService';
import { sendEvent } from '../../../utils/amplitude';

const Prizes = (props) => {
	const intl = useIntl();

	const handleButtonClick = (source) => {
		authService.login();
		sendEvent('Chicken Landing - Click Sign Up Button', {
			Language: intl.locale,
			Source: source,
		});
	};

	return (
		<div className="prizes ">
			<div className="prizes__title">{intl.formatMessage({ id: 'award.title' })}</div>
			<div className="prizes__untitle">{`${intl.formatMessage({ id: 'award.untitle' })}`}</div>
			<div className="prizes__items">
				<div className="prizes__items__day-quest day-quest">
					<div className="prizes__items__day-quest-item day-quest__item">
						<img className="day-quest__item-image" src={FDF} alt="Scope.gg Skin" />
						<span className="day-quest__item-title">
							StatTrak™ AK-47 / MW <br /> Phantom Disruptor
						</span>
						<span className="day-quest__item-prize">1 {intl.formatMessage({ id: 'days.day' })} </span>
					</div>
				</div>
				<div className="prizes__items__day-quest day-quest">
					<div className="prizes__items__day-quest-item day-quest__item">
						<img className="day-quest__item-image" src={SDF} alt="Scope.gg Skin" />
						<span className="day-quest__item-title">
							AK-47 <br /> MW Asiimov
						</span>
						<span className="day-quest__item-prize">2 {intl.formatMessage({ id: 'days.day' })}</span>
					</div>
				</div>
				<div className="prizes__items__day-quest day-quest">
					<div className="prizes__items__day-quest-item day-quest__item">
						<img className="day-quest__item-image" src={TDF} alt="Scope.gg Skin" />
						<span className="day-quest__item-title">
							M4A4 <br /> FN Hellfire
						</span>
						<span className="prizes__items__day-quest day-quest__item-prize">
							3 {intl.formatMessage({ id: 'days.day' })}
						</span>
					</div>
				</div>
				<div className="prizes__items__day-quest day-quest">
					<div className="prizes__items__day-quest-item day-quest__item">
						<img className="day-quest__item-image" src={FourthDF} alt="Scope.gg Skin" />
						<span className="day-quest__item-title">
							AWP <br /> MW Hyper Beast
						</span>
						<span className="day-quest__item-prize">4 {intl.formatMessage({ id: 'days.day' })}</span>
					</div>
				</div>
				<div className="prizes__items__day-quest day-quest">
					<div className="prizes__items__day-quest-item day-quest__item">
						<img className="day-quest__item-image" src={FifthDF} alt="Scope.gg Skin" />
						<span className="day-quest__item-title">
							M4A4 <br /> NEO-NOIR
						</span>
						<span className="day-quest__item-prize">5 {intl.formatMessage({ id: 'days.day' })}</span>
					</div>
				</div>
				<div className="prizes__items__day-quest day-quest">
					<div className="prizes__items__day-quest-item day-quest__item">
						<img className="day-quest__item-image" src={FDS} alt="Scope.gg Skin" />
						<span className="day-quest__item-title">
							AWP <br /> FN Corticera
						</span>
						<span className="day-quest__item-prize">1 {intl.formatMessage({ id: 'days.day' })}</span>
					</div>
				</div>
				<div className="prizes__items__day-quest day-quest">
					<div className="prizes__items__day-quest-item day-quest__item">
						<img className="day-quest__item-image" src={SDS} alt="Scope.gg Skin" />
						<span className="day-quest__item-title">
							SG 553 <br /> FN Colony IV
						</span>
						<span className="day-quest__item-prize">2 {intl.formatMessage({ id: 'days.day' })}</span>
					</div>
				</div>
				<div className="prizes__items__day-quest day-quest">
					<div className="prizes__items__day-quest-item day-quest__item">
						<img className="day-quest__item-image" src={TDS} alt="Scope.gg Skin" />
						<span className="day-quest__item-title">
							M4A1-S <br /> MW Hyper Beast
						</span>
						<span className="day-quest__item-prize">3 {intl.formatMessage({ id: 'days.day' })}</span>
					</div>
				</div>
				<div className="prizes__items__day-quest day-quest">
					<div className="prizes__items__day-quest-item day-quest__item">
						<img className="day-quest__item-image" src={FourthDS} alt="Scope.gg Skin" />
						<span className="day-quest__item-title">
							AK-47 <br /> MW Frontside Misty
						</span>
						<span className="day-quest__item-prize">4 {intl.formatMessage({ id: 'days.day' })}</span>
					</div>
				</div>
				<div className="prizes__items__day-quest day-quest">
					<div className="prizes__items__day-quest-item day-quest__item">
						<img className="day-quest__item-image" src={FifthDS} alt="Scope.gg Skin" />
						<span className="day-quest__item-title">
							M4A4 <br /> FN Buzz Kill
						</span>
						<span className="day-quest__item-prize">5 {intl.formatMessage({ id: 'days.day' })}</span>
					</div>
				</div>
				<div className="prizes__items__day-quest day-quest">
					<div className="prizes__items__day-quest-item day-quest__item">
						<img className="day-quest__item-image" src={FDT} alt="Scope.gg Skin" />
						<span className="day-quest__item-title">
							SSG <br /> 08 FN Dragonfire
						</span>
						<span className="day-quest__item-prize">1 {intl.formatMessage({ id: 'days.day' })}</span>
					</div>
				</div>
				<div className="prizes__items__day-quest day-quest">
					<div className="prizes__items__day-quest-item day-quest__item">
						<img className="day-quest__item-image" src={SDT} alt="Scope.gg Skin" />
						<span className="day-quest__item-title">
							USP-S <br /> MW Orion
						</span>
						<span className="day-quest__item-prize">2 {intl.formatMessage({ id: 'days.day' })}</span>
					</div>
				</div>
				<div className="prizes__items__day-quest day-quest">
					<div className="prizes__items__day-quest-item day-quest__item">
						<img className="day-quest__item-image" src={TDT} alt="Scope.gg Skin" />
						<span className="day-quest__item-title">
							M4A4 MW Desolate <br /> Space
						</span>
						<span className="day-quest__item-prize">3 {intl.formatMessage({ id: 'days.day' })}</span>
					</div>
				</div>
				<div className="prizes__items__day-quest day-quest">
					<div className="prizes__items__day-quest-item day-quest__item">
						<img className="day-quest__item-image" src={FourthDT} alt="Scope.gg Skin" />
						<span className="day-quest__item-title">
							FAMAS <br /> FN Commemoration
						</span>
						<span className="day-quest__item-prize">4 {intl.formatMessage({ id: 'days.day' })}</span>
					</div>
				</div>
				<div className="prizes__items__day-quest day-quest">
					<div className="prizes__items__day-quest-item day-quest__item">
						<img className="day-quest__item-image" src={FifthDT} alt="Scope.gg Skin" />
						<span className="day-quest__item-title">
							P90 <br /> MW Asiimov
						</span>
						<span className="day-quest__item-prize">5 {intl.formatMessage({ id: 'days.day' })}</span>
					</div>
				</div>
				<div className="prizes__items__day-quest day-quest">
					<div className="prizes__items__day-quest-item day-quest__item day-quest__item--knife">
						<img className="day-quest__item-image" src={KnifeImage} alt="Scope.gg Skin" />
						<span className="day-quest__item-title">StatTrak™ ★ Gut Knife</span>
						<span className="day-quest__item-prize">{intl.formatMessage({ id: 'award.award' })}</span>
					</div>
				</div>
			</div>
			<div className="prizes__divider" />
			<div className="prizes__bottom">
				<span className="prizes__bottom-title">{intl.formatMessage({ id: 'award.email' })}</span>
				{!props.isLoggedIn ? (
					<div className="prizes__bottom-register">
						<button
							className="prizes__bottom-button primary-button primary-button--steam"
							id="steam-3"
							onClick={() => handleButtonClick('Steam')}
						>
							<img src={Steam} alt="Scope.gg Steam Logo" />
							<span>{intl.formatMessage({ id: 'buttons.steam' })}</span>
						</button>
						<button
							className="prizes__bottom-button primary-button primary-button--faceit"
							id="faceit-3"
							onClick={() => handleButtonClick('Faceit')}
						>
							<img src={Faceit} alt="Scope.gg Steam Logo" />
							<span>{intl.formatMessage({ id: 'buttons.faceit' })}</span>
						</button>
					</div>
				) : null}
				<div className="prizes__bottom-subscribe">
					<span className="prizes__bottom-subscribe-title">{`${intl.formatMessage({ id: 'award.subscribe' })}`}</span>
					<div className="prizes__bottom-subscribe-social">
						<a href="https://www.facebook.com/Scopegg-105732910993994" className="social-link">
							<img src={fb} alt="Scope.gg Facebook" />
						</a>
						<a href="https://twitter.com/scope_gg" className="social-link">
							<img src={twitter} alt="Scope.gg Twitter" />
						</a>
						<a href="https://vk.com/scopegg" className="social-link">
							<img src={vk} alt="Scope.gg VK" />
						</a>
						<a href="https://www.instagram.com/scope_gg/" className="social-link">
							<img src={instagram} alt="Scope.gg Instagram" />
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	isLoggedIn: state.authReducer.isLoggedIn,
});

export default connect(mapStateToProps)(Prizes);
