import React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'gatsby-plugin-react-intl';

import KnifeImage from '../../../assets/images/knife.png';
import ScopeMint from '../../../assets/images/scope-mint.png';
import { eventService } from '../../../services/eventService';
import { setIsParticipant } from '../../../state/auth/actions';

const Knife = (props) => {
	const intl = useIntl();

	const handleJoinButton = async () => {
		if (props.events.length) {
			const result = await eventService.participate({ events: props.events });

			if (result.length) {
				props.setIsParticipant(true);
			}
		}
	};

	return (
		<div className="knife">
			<div className="knife__title">{intl.formatMessage({ id: 'knife.title' })}</div>
			<div className="knife__untitle">{`${intl.formatMessage({ id: 'knife.untitle' })}`}</div>
			<div className="knife__image">
				<img className="knife__image-main" src={KnifeImage} alt="Scope.gg Knife" />
				<div className="knife__image-title">
					<span>StatTrak™ ★ Gut Knife от</span>
					<img src={ScopeMint} alt="Scope.gg Mint Logo" />
				</div>
			</div>
			<div className="knife__reward-title">{`${intl.formatMessage({ id: 'knife.award' })}`}</div>
			<div className="knife__actions">
				{props.isParticipant ? (
					<button className="knife__button participant-button active" onClick={handleJoinButton}>
						{intl.formatMessage({ id: 'buttons.participant' })}
					</button>
				) : (
					<button className="knife__button participant-button" id="join-3" onClick={handleJoinButton}>
						{intl.formatMessage({ id: 'buttons.join' })}
					</button>
				)}
				{!props.isLoggedIn ? (
					<span className="knife__actions-untitle">{intl.formatMessage({ id: 'buttons.untitle' })}</span>
				) : null}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	isParticipant: state.authReducer.isParticipant,
	events: state.authReducer.events,
	isLoggedIn: state.authReducer.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
	setIsParticipant: (payload) => dispatch(setIsParticipant(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Knife);
