import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';

import FifthDay from '../../../assets/images/ru/days/fifth/fifth.png';
import ENFifthDay from '../../../assets/images/en/days/fifth/fifth.png';
import FirstItem from '../../../assets/images/ru/days/fifth/first-item.png';
import SecondItem from '../../../assets/images/ru/days/fifth/second-item.png';
import ThirdItem from '../../../assets/images/ru/days/fifth/third-item.png';

const FifthDayPage = () => {
	const intl = useIntl();

	return (
		<div className="fifth-day ">
			<div className="fifth-day__image">
				{intl.locale === 'ru' ? <img src={FifthDay} alt="" /> : <img src={ENFifthDay} alt="" />}
			</div>
			<div className="fifth-day__content">
				<h3 className="fifth-day__title days-title">{intl.formatMessage({ id: 'days.start' })}</h3>
				<span className="fifth-day__untitle days-untitle">
					{intl.formatMessage({ id: 'day5.firstTitle' })}
					<span className="number">1</span>
				</span>
				<div className="fifth-day__quests day-quests">
					<div className="fifth-day__quest day-quest">
						<div className="fifth-day__quest__item day-quest__item">
							<img className="fifth-day__quest__item-image day-quest__item-image" src={FirstItem} alt="Scope.gg Skin" />
							<span className="fifth-day__quest__item-title day-quest__item-title">
								M4A4 <br /> MW Neo-Noir
							</span>
							<span className="fifth-day__quest-item-prize day-quest__item-prize">
								{intl.formatMessage({ id: 'days.award' })}
							</span>
						</div>
						<div className="fifth-day__quest-content day-quest__content">
							<span className="fifth-day__quest-content-title day-quest__content-title">
								{intl.formatMessage({ id: 'days.task' })} #1:
							</span>
							<span className="fifth-day__quest-content-untitle day-quest__content-untitle">
								{`${intl.formatMessage({ id: 'day5.task1' })}`}
							</span>
						</div>
					</div>
					<div className="fifth-day__quest day-quest">
						<div className="fifth-day__quest__item day-quest__item">
							<img
								className="fifth-day__quest__item-image day-quest__item-image"
								src={SecondItem}
								alt="Scope.gg Skin"
							/>
							<span className="fifth-day__quest__item-title day-quest__item-title">
								M4A4 <br /> FN Buzz Kill
							</span>
							<span className="fifth-day__quest-item-prize day-quest__item-prize">
								{intl.formatMessage({ id: 'days.award' })}
							</span>
						</div>
						<div className="fifth-day__quest-content day-quest__content">
							<span className="fifth-day__quest-content-title day-quest__content-title">
								{intl.formatMessage({ id: 'days.task' })} #2:
							</span>
							<span className="fifth-day__quest-content-untitle day-quest__content-untitle">
								{`${intl.formatMessage({ id: 'day5.task2' })}`}
							</span>
						</div>
					</div>
					<div className="fifth-day__quest day-quest">
						<div className="fifth-day__quest__item day-quest__item">
							<img className="fifth-day__quest__item-image day-quest__item-image" src={ThirdItem} alt="Scope.gg Skin" />
							<span className="fifth-day__quest__item-title day-quest__item-title">
								P90 <br /> MW Asiimov
							</span>
							<span className="fifth-day__quest-item-prize day-quest__item-prize">
								{intl.formatMessage({ id: 'days.award' })}
							</span>
						</div>
						<div className="fifth-day__quest-content day-quest__content">
							<span className="fifth-day__quest-content-title day-quest__content-title">
								{intl.formatMessage({ id: 'days.task' })} #3:
							</span>
							<span className="fifth-day__quest-content-untitle day-quest__content-untitle">
								{`${intl.formatMessage({ id: 'day5.task3' })}`}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FifthDayPage;
