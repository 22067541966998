import React from 'react';
import { connect } from 'react-redux';
import { useIntl, Link } from 'gatsby-plugin-react-intl';

import Steam from '../../../assets/images/steam.svg';
import Faceit from '../../../assets/images/faceit.svg';
import BoardLeft from '../../../assets/images/board-left.png';
import BoardRight from '../../../assets/images/board-right.png';
import { authService } from '../../../services/authService';
import { eventService } from '../../../services/eventService';
import { setIsParticipant } from '../../../state/auth/actions';
import { sendEvent } from '../../../utils/amplitude';

const Join = (props) => {
	const intl = useIntl();

	const handleJoinButton = async () => {
		if (props.events.length) {
			const result = await eventService.participate({ events: props.events });

			if (result.length) {
				props.setIsParticipant(true);
			}
		}

		sendEvent('Chicken Landing / How to take part / Click Participating', {
			Language: intl.locale,
		});
	};

	const handleButtonClick = (source) => {
		authService.login();
		sendEvent('Chicken Landing / How to take part / Click Sign Up Button', {
			Language: intl.locale,
			Source: source,
		});
	};

	return (
		<div className="join ">
			<h2 className="join__title">{`${intl.formatMessage({ id: 'join.title' })}`}</h2>
			<span className="join__untitle">{`${intl.formatMessage({ id: 'join.first' })}`}</span>
			<div className="join__actions">
				<div className="join__action">
					<button
						className="join__actions__button primary-button primary-button--steam"
						id="steam-2"
						onClick={() => handleButtonClick('Steam')}
					>
						<img src={Steam} alt="Scope.gg Steam Logo" />
						<span>{intl.formatMessage({ id: 'buttons.steam' })}</span>
					</button>
				</div>
				<div className="join__action">
					<button
						className="join__actions__button primary-button primary-button--faceit"
						id="faceit-2"
						onClick={() => handleButtonClick('Faceit')}
					>
						<img src={Faceit} alt="Scope.gg Steam Logo" />
						<span>{intl.formatMessage({ id: 'buttons.faceit' })}</span>
					</button>
				</div>
			</div>
			<span className="join__sub-title">{`${intl.formatMessage({ id: 'join.second' })}`}</span>
			<div className="join__button-wrap">
				{props.isParticipant ? (
					<button className="join__button participant-button active" onClick={handleJoinButton}>
						{intl.formatMessage({ id: 'buttons.participant' })}
					</button>
				) : (
					<button className="join__button participant-button" id="join-2" onClick={handleJoinButton}>
						{intl.formatMessage({ id: 'buttons.join' })}
					</button>
				)}
				{!props.isLoggedIn ? (
					<span className="join__button-untitle">{intl.formatMessage({ id: 'buttons.untitle' })}</span>
				) : null}
			</div>
			<div className="join__boards">
				<div className="join__board">
					<img src={BoardLeft} alt="" />
					<span>{`${intl.formatMessage({ id: 'join.left' })}`}</span>
					<Link
						to={'/leaderboard'}
						className="leaderboard-button"
						onClick={() =>
							sendEvent('Chicken Landing / How to take part / Open Leaderboard', {
								Language: intl.locale,
							})
						}
					>
						{intl.formatMessage({ id: 'header.leaderboard' })}
					</Link>
				</div>
				<div className="join__board">
					<img src={BoardRight} alt="" />
					<span>{`${intl.formatMessage({ id: 'join.right' })}`}</span>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	isParticipant: state.authReducer.isParticipant,
	isLoggedIn: state.authReducer.isLoggedIn,
	events: state.authReducer.events,
});

const mapDispatchToProps = (dispatch) => ({
	setIsParticipant: (payload) => dispatch(setIsParticipant(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Join);
