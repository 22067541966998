import { axiosInstance } from '../utils/api';

class EventService {
	participate(data) {
		return axiosInstance.post('/marketingEvents/participateInEvents', data);
	}

	checkIsParticipant(data) {
		return axiosInstance.post('/marketingEvents/checkIfAlreadyParticipatingInEvents', data);
	}

	getEventDays(data) {
		return axiosInstance.post('/marketingEvents/getEventDays', data);
	}

	getTop(data) {
		return axiosInstance.post('/leaderboards/getTop', data);
	}

	getArchiveTopWinners(data) {
		return axiosInstance.post('/marketingEvents/getEventsArchiveWinners', data);
	}

	getUserPosition(data) {
		return axiosInstance.post('/leaderboards/getUserData', data);
	}

	getArchiveTop(data) {
		return axiosInstance.post('/marketingEvents/getEventArchiveTop', data);
	}
}

export const eventService = new EventService();
