import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';

import SecondDay from '../../../assets/images/ru/days/second/second.png';
import ENSecondDay from '../../../assets/images/en/days/second/second.png';
import FirstItem from '../../../assets/images/ru/days/second/first-item.png';
import SecondItem from '../../../assets/images/ru/days/second/second-item.png';
import ThirdItem from '../../../assets/images/ru/days/second/third-item.png';

const SecondDayPage = () => {
	const intl = useIntl();

	return (
		<div className="second-day ">
			<div className="second-day__image">
				{intl.locale === 'ru' ? <img src={SecondDay} alt="" /> : <img src={ENSecondDay} alt="" />}
			</div>
			<div className="second-day__content">
				<h3 className="second-day__title days-title">{intl.formatMessage({ id: 'days.start' })}</h3>
				<span className="second-day__untitle days-untitle">
					{`${intl.formatMessage({ id: 'day2.firstTitle' })}`}
					<span className="number">1</span>
				</span>
				<div className="second-day__quests day-quests">
					<div className="second-day__quest day-quest">
						<div className="second-day__quest__item day-quest__item">
							<img
								className="second-day__quest__item-image day-quest__item-image"
								src={FirstItem}
								alt="Scope.gg Skin"
							/>
							<span className="second-day__quest__item-title day-quest__item-title">
								AK-47 <br /> MW Asiimov
							</span>
							<span className="second-day__quest-item-prize day-quest__item-prize">
								{intl.formatMessage({ id: 'days.award' })}
							</span>
						</div>
						<div className="second-day__quest-content day-quest__content">
							<span className="second-day__quest-content-title day-quest__content-title">
								{intl.formatMessage({ id: 'days.task' })} #1:
							</span>
							<span className="second-day__quest-content-untitle day-quest__content-untitle">
								{`${intl.formatMessage({ id: 'day2.task1' })}`}
							</span>
						</div>
					</div>
					<div className="second-day__quest day-quest">
						<div className="second-day__quest__item day-quest__item">
							<img
								className="second-day__quest__item-image day-quest__item-image"
								src={SecondItem}
								alt="Scope.gg Skin"
							/>
							<span className="second-day__quest__item-title day-quest__item-title">
								SG 553 <br /> FN Colony IV
							</span>
							<span className="second-day__quest-item-prize day-quest__item-prize">
								{intl.formatMessage({ id: 'days.award' })}
							</span>
						</div>
						<div className="second-day__quest-content day-quest__content">
							<span className="second-day__quest-content-title day-quest__content-title">
								{intl.formatMessage({ id: 'days.task' })} #2:
							</span>
							<span className="second-day__quest-content-untitle day-quest__content-untitle">
								{`${intl.formatMessage({ id: 'day2.task2' })}`}
							</span>
						</div>
					</div>
					<div className="second-day__quest day-quest">
						<div className="second-day__quest__item day-quest__item">
							<img
								className="second-day__quest__item-image day-quest__item-image"
								src={ThirdItem}
								alt="Scope.gg Skin"
							/>
							<span className="second-day__quest__item-title day-quest__item-title">
								USP-S <br /> MW Orion
							</span>
							<span className="second-day__quest-item-prize day-quest__item-prize">
								{intl.formatMessage({ id: 'days.award' })}
							</span>
						</div>
						<div className="second-day__quest-content day-quest__content">
							<span className="second-day__quest-content-title day-quest__content-title">
								{intl.formatMessage({ id: 'days.task' })} #3:
							</span>
							<span className="second-day__quest-content-untitle day-quest__content-untitle">
								{`${intl.formatMessage({ id: 'day2.task3' })}`}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SecondDayPage;
