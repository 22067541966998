import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';

import RightChicken from '../../../assets/images/chicken.png';
import ScopeMint from '../../../assets/images/scope-mint.png';
import Mice from '../../../assets/images/mice.png';
import RUChallenge1 from '../../../assets/images/ru/challenge-1.png';
import RUChallenge2 from '../../../assets/images/ru/challenge-2.png';
import RUChallenge3 from '../../../assets/images/ru/challenge-3.png';
import ENChallenge1 from '../../../assets/images/en/challenge-1.png';
import ENChallenge2 from '../../../assets/images/en/challenge-2.png';
import ENChallenge3 from '../../../assets/images/en/challenge-3.png';

const Chicken = (props) => {
	const intl = useIntl();

	return (
		<div className="chicken ">
			<h1 className="chicken__title">
				{intl.formatMessage({ id: 'chicken.title' })}
				<br />
				<b>{intl.formatMessage({ id: 'chicken.bold' })}</b>
			</h1>
			<div className="chicken__from">
				<span>{intl.formatMessage({ id: 'chicken.eventTitle' })}</span>
				<img src={ScopeMint} alt="Scope.gg Mint Logo" />
			</div>
			<div className="chicken__right-image">
				<img src={RightChicken} alt="Scope.gg Chicken0" />
			</div>
			<div className="chicken__bottom-mice">
				<img src={Mice} alt="Scope.gg Mice" />
			</div>
			<div className="chicken__challenges">
				<div className="chicken__challenge">
					{intl.locale === 'ru' ? (
						<img src={RUChallenge1} alt="Chicken Challenge" />
					) : (
						<img src={ENChallenge1} alt="Chicken Challenge" />
					)}
				</div>
				<div className="chicken__challenge">
					{intl.locale === 'ru' ? (
						<img src={RUChallenge2} alt="Chicken Challenge" />
					) : (
						<img src={ENChallenge2} alt="Chicken Challenge" />
					)}
				</div>
				<div className="chicken__challenge">
					{intl.locale === 'ru' ? (
						<img src={RUChallenge3} alt="Chicken Challenge" />
					) : (
						<img src={ENChallenge3} alt="Chicken Challenge" />
					)}
				</div>
			</div>
			<div className="chicken__award">{`${intl.formatMessage({ id: 'chicken.award' })}`}</div>
		</div>
	);
};

export default Chicken;
