import React from 'react';

import FirstDay from '../../../assets/images/ru/days/first/first.png';
import ENFirstDay from '../../../assets/images/en/days/first/first.png';
import FirstItem from '../../../assets/images/ru/days/first/first-item.png';
import SecondItem from '../../../assets/images/ru/days/first/second-item.png';
import ThirdItem from '../../../assets/images/ru/days/first/third-item.png';
import { useIntl } from 'gatsby-plugin-react-intl';

const FirstDayPage = () => {
	const intl = useIntl();

	return (
		<div className="first-day -responsive">
			<div className="first-day__image">
				{intl.locale === 'ru' ? <img src={FirstDay} alt="" /> : <img src={ENFirstDay} alt="" />}
			</div>
			<div className="first-day__content">
				<h3 className="first-day__title days-title">{intl.formatMessage({ id: 'days.start' })}</h3>
				<span className="first-day__untitle days-untitle">
					{intl.formatMessage({ id: 'day1.firstTitle' })} <span className="number">1</span>
				</span>

				<div className="first-day__quests day-quests">
					<div className="first-day__quest day-quest">
						<div className="first-day__quest__item day-quest__item">
							<img className="first-day__quest__item-image day-quest__item-image" src={FirstItem} alt="Scope.gg Skin" />
							<span className="first-day__quest__item-title day-quest__item-title">
								StatTrak™ AK-47 / MW Phantom Disruptor
							</span>
							<span className="first-day__quest-item-prize day-quest__item-prize">
								{intl.formatMessage({ id: 'days.award' })}
							</span>
						</div>
						<div className="first-day__quest-content day-quest__content">
							<span className="first-day__quest-content-title day-quest__content-title">
								{intl.formatMessage({ id: 'days.task' })} #1:
							</span>
							<span className="first-day__quest-content-untitle day-quest__content-untitle">
								{`${intl.formatMessage({ id: 'day1.task1' })}`}
							</span>
						</div>
					</div>
					<div className="first-day__quest day-quest">
						<div className="first-day__quest__item day-quest__item">
							<img
								className="first-day__quest__item-image day-quest__item-image"
								src={SecondItem}
								alt="Scope.gg Skin"
							/>
							<span className="first-day__quest__item-title day-quest__item-title">
								AWP <br /> FN Corticera
							</span>
							<span className="first-day__quest-item-prize day-quest__item-prize">
								{intl.formatMessage({ id: 'days.award' })}
							</span>
						</div>
						<div className="first-day__quest-content day-quest__content">
							<span className="first-day__quest-content-title day-quest__content-title">
								{intl.formatMessage({ id: 'days.task' })} #2:
							</span>
							<span className="first-day__quest-content-untitle day-quest__content-untitle">
								{`${intl.formatMessage({ id: 'day1.task2' })}`}
							</span>
						</div>
					</div>
					<div className="first-day__quest day-quest">
						<div className="first-day__quest__item day-quest__item">
							<img className="first-day__quest__item-image day-quest__item-image" src={ThirdItem} alt="Scope.gg Skin" />
							<span className="first-day__quest__item-title day-quest__item-title">
								SSG <br /> 08 FN Dragonfire
							</span>
							<span className="first-day__quest-item-prize day-quest__item-prize">
								{intl.formatMessage({ id: 'days.award' })}
							</span>
						</div>
						<div className="first-day__quest-content day-quest__content">
							<span className="first-day__quest-content-title day-quest__content-title">
								{intl.formatMessage({ id: 'days.task' })} #3:
							</span>
							<span className="first-day__quest-content-untitle day-quest__content-untitle">
								{`${intl.formatMessage({ id: 'day1.task3' })}`}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FirstDayPage;
