import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'gatsby-plugin-react-intl';
import { connect } from 'react-redux';
import SwiperCore, { Navigation } from 'swiper';
import { sendEvent } from '../utils/amplitude';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

import Layout from '../components/layout/layout';
import Chicken from '../components/chicken/event/chicken';
import Join from '../components/chicken/event/join';
import FirstDayPage from '../components/chicken/days/first';
import SecondDayPage from '../components/chicken/days/second';
import ThirdDayPage from '../components/chicken/days/third';
import FourthDayPage from '../components/chicken/days/fourth';
import FifthDayPage from '../components/chicken/days/fifth';
import Knife from '../components/chicken/event/knife';
import Prizes from '../components/chicken/event/prizes';

SwiperCore.use([Navigation]);

const ChickenPage = (props) => {
	const intl = useIntl();
	const [activeSlide, setActiveSlide] = useState(0);
	const [swiperApi, setSwiperApi] = useState({});

	useEffect(() => {
		sendEvent('Chicken Landing - Loadpage', {
			Language: intl.locale,
		});
	}, []);

	const onSlideChange = (activeIndex) => {
		setActiveSlide(activeIndex);
	};

	return (
		<Layout>
			<Helmet>
				<title>{intl.formatMessage({ id: 'seo.indexTitle' })}</title>
				{process.env.GATSBY_ACTIVE_ENV !== 'production' ? <meta name="robots" content="noindex, nofollow" /> : null}
				<meta name="description" content={intl.formatMessage({ id: 'seo.indexMeta' })} />
			</Helmet>
			<div className="section">
				<Chicken />
			</div>
			<div className="section" id="join">
				<Join />
			</div>
			<Swiper
				id="days"
				className="section slides"
				navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
				slidesPerView={1}
				onSlideChange={(swiper) => onSlideChange(swiper.activeIndex)}
				onSwiper={(swiper) => setSwiperApi(swiper)}
			>
				<SwiperSlide className="slide">
					<FirstDayPage />
				</SwiperSlide>
				<SwiperSlide className="slide">
					<SecondDayPage />
				</SwiperSlide>
				<SwiperSlide className="slide">
					<ThirdDayPage />
				</SwiperSlide>
				<SwiperSlide className="slide">
					<FourthDayPage />
				</SwiperSlide>
				<SwiperSlide className="slide">
					<FifthDayPage />
				</SwiperSlide>
				<button className="swiper-button-prev" />
				<button className="swiper-button-next" />
			</Swiper>
			<div className="section slides">
				<div className="slides-tabs">
					<div className={`slide-tab ${activeSlide === 0 ? 'active' : ''}`}>
						<span className="day">{intl.formatMessage({ id: 'days.day1' })}</span>
					</div>
					<div className={`slide-tab ${activeSlide === 1 ? 'active' : ''}`}>
						<span className="day">{intl.formatMessage({ id: 'days.day2' })}</span>
					</div>
					<div className={`slide-tab ${activeSlide === 2 ? 'active' : ''}`}>
						<span className="day">{intl.formatMessage({ id: 'days.day3' })}</span>
					</div>
					<div className={`slide-tab ${activeSlide === 3 ? 'active' : ''}`}>
						<span className="day">{intl.formatMessage({ id: 'days.day4' })}</span>
					</div>
					<div className={`slide-tab ${activeSlide === 4 ? 'active' : ''}`}>
						<span className="day">{intl.formatMessage({ id: 'days.day5' })}</span>
					</div>
				</div>
			</div>
			<div className="section">
				<Knife />
			</div>
			<div className="section" id="prizes">
				<Prizes />
			</div>
		</Layout>
	);
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(null, mapDispatchToProps)(ChickenPage);
